import useApi from '../useApi';

const useKeyAccountService = () => {
  const {
    requestState, payload, error, api,
  } = useApi();

  const getKeyAccountContextData = (keyAccountCode) => api({
    method: 'get',
    url: `/key-account-clients/${keyAccountCode}`,
  });

  const getAllKeyAccountsDomains = () => api({
    method: 'get',
    url: '/key-account-clients/domains',
  });

  return [
    requestState,
    payload,
    error,
    {
      getKeyAccountContextData,
      getAllKeyAccountsDomains,
    }];
};

export default useKeyAccountService;
