import { isNil } from 'lodash';

const mockRequest = (data, url) => {
  if (!isNil(data) && url) {
    switch (url) {
      case '':
        return data;
      default:
        return data;
    }
  } else {
    return data;
  }
};

export default mockRequest;
