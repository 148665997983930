import omit from 'lodash';
import { useAppContext } from '../store/AppContext';
import isCachedValueExpired from '../utils/isCachedValueExpired';

const useCache = () => {
  const [{ apiCached }, stateDispatch] = useAppContext();

  const handleCachedResults = async (cache, dispatch) => {
    if (!cache) return null;

    if (
      apiCached
      && cache?.key
      && cache?.value
      && apiCached[cache.key]
      && apiCached[cache.key][cache.value]
    ) {
      if (isCachedValueExpired(apiCached[cache.key][cache.value])) {
        stateDispatch({ type: 'SET_API_CACHE', payload: omit(apiCached, [cache.key, cache.value]) });
        return null;
      }

      dispatch({ type: 'REQUEST_SUCCESS', payload: apiCached[cache.key][cache.value].value });
      return apiCached[cache.key][cache.value].value;
    }

    return null;
  };

  return {
    handleCachedResults,
  };
};

export default useCache;
