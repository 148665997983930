import api from '../../_api';

// Get the Key Accounts List
export const getKeyAccounts = async () => {
  const response = await api.get('/admin/key-account-clients');
  return response.data.keyAccountClients;
};

// Create a Key Account
export const createKeyAccount = (account) => api.post('/admin/key-account-clients', account);

/**
 *
 * @param {number} code
 * @returns {Promise<KeyAccountClient>}
 */
export const getKeyAccount = async (code) => {
  const response = await api.get(`/admin/key-account-clients/${code}`);

  return response.data.keyAccountClient;
};

// Update some of the Key Account details
export const updateKeyAccount = ({ data, code }) => api.patch(`/admin/key-account-clients/${code}`, data);

// Update all the Key Account details
export const updateAllKeyAccount = ({ data, code }) => api.put(`/admin/key-account-clients/${code}`, data);

// Get the Key Account clients list
export const getKeyAccountClients = (code) => api.get(`/admin/key-account-clients/${code}/clients`);

// Import a Key Account clients list
export const importKeyAccountClients = ({ code, formData }) =>
  api.post(`/admin/key-account-clients/${code}/import-clients`, formData, {
    Accept: '*/*',
    'Content-Type': 'multipart/form-data',
  });

/**
 *
 * @param {Object} params
 * @param {string} params.startAt
 * @param {string} params.endAt
 * @returns {Promise<any>}
 */
export const getKeyAccountsStats = async ({ startAt, endAt }) => {
  const response = await api.get('/admin/stats/key-account-clients', {
    params: {
      startAt,
      endAt,
    },
  });

  return response.data;
};

/**
 *
 * @param {Object} params
 * @param {string} params.keyAccountId
 * @param {string} params.startAt
 * @param {string} params.endAt
 * @returns {Promise<KeyAccountClientStat[]>}
 */
export const getKeyAccountClientsStats = async ({ keyAccountId, startAt, endAt }) => {
  const { data } = await api.get(`/admin/stats/key-account-clients/${keyAccountId}/clients`, {
    params: {
      startAt,
      endAt,
    },
  });
  return data;
};

// Get the Key Account Admins List
/**
 *
 * @param {number} code
 * @returns {Promise<KeyAccountAdmin[]>}
 */
export const getKeyAccountAdmins = async (code) => {
  const response = await api.get(`/admin/key-account-clients/${code}/admin`);
  return response.data.keyAccountClientAdmins;
};

// Create a Key Account Admin
export const createKeyAccountAdmin = ({ code, data }) => api.post(`/admin/key-account-clients/${code}/admin`, data);

/**
 *
 * @param {number} adminId
 * @returns {Promise<KeyAccountAdmin>}
 */
export const getKeyAccountAdmin = async (adminId) => {
  const response = await api.get(`/admin/key-account-client-admin/${adminId}`);
  return response.data.keyAccountClient;
};

// Update a Key Account Admin
export const updateKeyAccountAdmin = ({ id, data }) => api.patch(`/admin/key-account-client-admin/${id}`, data);

// Delete a Key Account Admin
export const deleteKeyAccountAdmin = (id) => api.delete(`/admin/key-account-client-admin/${id}`);

// Update a Key Account Admin
export const updateKeyAccountAdminPassword = ({ id, data }) =>
  api.put(`/admin/key-account-client-admin/${id}/password`, data);

export const getMyKeyAccountClient = async () => {
  const result = await api.get('/admin/my-key-account-client');
  return result.data.keyAccountClient;
};

// Get all Key Account Admin statistics
export const getKeyAccountAdminStats = (startAt, endAt) =>
  api.get('/admin/my-key-account-client/stats', {
    params: {
      startAt,
      endAt,
    },
  });

// Get all Key Account Admin orders
export const getKeyAccountAdminOrders = (values) =>
  api.post('/admin/my-key-account-client/order', {
    ...values,
    months: values.months || [],
  });

// Get a Key Account Admin order details
export const getKeyAccountAdminOrder = (id) => api.get(`/admin/my-key-account-client/order/${id}`);

// Get an order invoice url
export const getInvoiceUrl = ({ orderNumber, invoiceType }) =>
  api.get(`/admin/my-key-account-client/order/${orderNumber}/invoice-url`, {
    params: {
      invoiceType,
    },
  });

// Delete one or many orders
export const deleteOrdersByNumbers = ({ orderNumbers, comment }) =>
  api.delete('/admin/my-key-account-client/order', {
    data: {
      orderNumbers,
      comment,
    },
  });

// Get all Key Account Admin clients
export const getKeyAccountAdminClients = (values) => api.post('/admin/my-key-account-client/client', values);

// Export the clients list
export const getKeyAccountAdminClientsExport = () => api.get('/admin/my-key-account-client/client/export');
